
.tacos-acos-report{
  .table-tacos {
    width: 100%;
    height: calc(100vh - 200px);
    display: block;
    overflow: auto;

    td {
      border: 1px #fff solid;
      text-align: center;
      color: #fff;
      padding: 5px;

      &:first-child {
        color: #343a40;
        border: none;
        width: 60px;
        text-align: start;
      }
    }

    tfoot {
      td {
        color: #343a40;
        border: none;
        writing-mode: sideways-lr;
        padding-top: 20px;
      }
    }
  }

  .red {
    background-color: #E31A1A;
  }
  .green {
    background-color: #00B153;
  }
  .orange {
    background-color: #F17535;
  }

  .badges {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &__item {
      color: #fff;
      padding: 0 10px;
      border-radius: 4px;
      margin-right: 10px;
      height: 30px;
      display: flex;
      align-items: center;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
