
.table-tacos {
  width: 100%;
  display: block;
  overflow: auto;
  padding-bottom: 0.8rem;
  border-collapse: separate;

  th {
    padding: 0 0.85rem;
  }

  td:nth-child(2) { border-top-left-radius: 10px; }
  td:last-child { border-top-right-radius: 10px; }
  td:nth-child(2) { border-bottom-left-radius: 10px; }
  td:last-child { border-bottom-right-radius: 10px; }

  &__cell {
    text-align: center;
    padding: 0.2rem 0.3rem;
    vertical-align: top;
    line-height: 130%;

    &._first {
      border: 1px #fff solid;
      line-height: 150%;
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
    }
  }
}

.product-brand-acos-tacos-widget {
  .badges {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-width: 300px;

    &__item {
      color: #fff;
      padding: 0 10px;
      border-radius: 14px;
      margin-right: 10px;
      height: 30px;
      display: flex;
      align-items: center;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
